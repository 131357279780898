import React from "react";
import logo from "../resources/images/logo.png";
import { TypeAnimation } from 'react-type-animation';
import { Button, Col, Row } from 'antd';
import controller from "../resources/images/controller.png"
import circuit from "../resources/images/circuit.png"
import infinity from "../resources/images/infinity.png"
import { Link, useHistory } from "react-router-dom";

const IndexPage = () => {
    const history = useHistory()
    const handleJoinButtonClick = () => {
        if (localStorage.getItem("accessToken") !== null) {
            history.push("/dashboard");
        } else {
            history.push("/login");
        }
    };

    return (
        <div className="bg-white">
            <div>
                <div className="hero-banner">
                    <div className="hero-text-box">
                        <div className="typed-top-title">The Game Circuit<sup>TM</sup></div>
                        <div className="typed-title-box">
                            <div className="typed-prefix">The Place To </div>
                            <TypeAnimation
                                className="typed-hero"
                                preRenderFirstString={true}
                                sequence={[
                                    'Game',
                                    1500,
                                    'Talk',
                                    1500,
                                    'Have Fun',
                                    1500,
                                    'Never Stop Gaming',
                                    1500,
                                    'Cure boredom',
                                    1500,
                                    'Connect',
                                    1500,
                                    'Compete',
                                    1500,
                                    'Conquer',
                                    1500
                                ]}
                                wrapper="span"
                                speed={50}
                                style={{ fontSize: '2em', display: 'inline-block' }}
                                repeat={Infinity}
                            />
                        </div>
                        <Button onClick={handleJoinButtonClick} size="large" className="hero-button">Join The Fun!</Button>
                    </div>

                </div>
                <Row className="section-two">
                    <Col className="first-one" span={12} 
                    xs={{
                        order: 1,
                    }}
                        sm={{
                            order: 1,
                        }}
                        md={{
                            order: 2,
                        }}
                        lg={{
                            order: 2,
                        }}>
                            <img className="section-image" src={circuit}></img>
                    </Col>
                    <Col span={12} xs={{
                        order: 1,
                    }}
                        sm={{
                            order: 1,
                        }}
                        md={{
                            order: 2,
                        }}
                        lg={{
                            order: 2,
                        }}>
                        <div className="second-one-inner">
                            <div className="second-title">Social Dynamic Group Calls</div>
                            <div className="second-description">We match individuals for group calls according to their social preferences and social behavior</div>
                        </div>
                    </Col>
                </Row>
                <Row className="section-three">
                    <Col span={12} 
                    style={{display: 'flex', justifyContent: 'center'}}
                    xs={{
                        order: 1,
                    }}
                        sm={{
                            order: 1,
                        }}
                        md={{
                            order: 2,
                        }}
                        lg={{
                            order: 2,
                        }}>
                        <div className="second-one-inner">
                            <div className="second-title">Group Game Suggestions</div>
                            <div className="second-description">We analyze various factors to suggest group games tailored to your preferences</div>
                        </div>
                    </Col>
                    <Col className="first-one" span={12} 
                    xs={{
                        order: 1,
                    }}
                        sm={{
                            order: 1,
                        }}
                        md={{
                            order: 2,
                        }}
                        lg={{
                            order: 2,
                        }}>
                            <img className="section-image" src={controller}></img>
                    </Col>
                </Row>
                <Row className="section-four">
                    <Col className="first-one" span={12} 
                    xs={{
                        order: 1,
                    }}
                        sm={{
                            order: 1,
                        }}
                        md={{
                            order: 2,
                        }}
                        lg={{
                            order: 2,
                        }}>
                            <img className="section-image" src={infinity}></img>
                    </Col>
                    <Col span={12} xs={{
                        order: 1,
                    }}
                        sm={{
                            order: 1,
                        }}
                        md={{
                            order: 2,
                        }}
                        lg={{
                            order: 2,
                        }}>
                        <div className="second-one-inner">
                            <div className="second-title">Never Stop</div>
                            <div className="second-description">Experience uninterrupted gaming with compatible players, ensuring enjoyable sessions even when your friends are unavailable. </div>
                        </div>
                    </Col>
                </Row>
                <div class="section-footer">
                    <div>Copyright reserved by <a target="_blank" href="https://www.game-circuit.com">Game-Circuit</a></div>
                    <div>Developed by <a target="_blank" href="https://www.linkedin.com/in/masudul-hasan-shawon/">Masudul Hasan Shawon</a></div>
                </div>
            </div>
        </div>

    );
};

export default IndexPage;
