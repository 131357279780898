import React, { useEffect } from "react";
import controller from "../resources/images/contorller-c.png";
import blackcircuit from "../resources/images/b-c-r.png";
import RegisterButtons from "./RegisterButtons";
import { Link } from "react-router-dom";

import "./RegisterPage.css";

const RegisterPage = ({ onLogin }) => {
  
  return (
    <div className="register_page_container">
      <div className="register_page_panel" style={{marginTop: '-20vh'}}>
        {/* <img style={{width: '380px'}} src={logo} className="register_page_image"></img> */}
        <div className="login-header">Sign Up</div>
        <RegisterButtons onLogin={onLogin} />
        <p className="bottom-text" style={{color: 'white'}}>Already have an account? <Link to="/login" style={{color: '#f3e885', textDecoration: 'none'}}>Login</Link> here.</p>
      </div>
      <img className="left-bg-img" src={blackcircuit}></img>
      <img className="right-bg-img" src={controller}></img>
    </div>
  );
};

export default RegisterPage;
