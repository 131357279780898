import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import { EyeInvisibleOutlined, EyeTwoTone, MailOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import store from "../store/store";
import { setAuth } from "../store/actions";

const LoginButtons = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();

  const handleLogin = async () => {
    try {
      const response = await fetch(`${process?.env?.REACT_APP_API_URL}/api/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || "Login failed");
      }

      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("user", JSON.stringify(data.user));
      store.dispatch(setAuth(data.user));
      onLogin();
      history.push("/");
    } catch (error) {
      console.error("Login error:", error);
      toast.error(error.message || "Login failed. Please try again.");
    }
  };

  return (
    <>
      <div className="connecting_buttons_container">

        <Input
          className="card-input"
          size="large"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          prefix={<MailOutlined />}
        />


        <Input.Password
          className="card-input"
          size="large"
          style={{ marginTop: '15px' }}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
        />

        <Button
          size="large"
          className="card-button"
          block
          style={{ marginTop: '15px' }}
          onClick={handleLogin}
        >
          Confirm
        </Button>
      </div>
      <Toaster />
    </>
  );
};

export default LoginButtons;
