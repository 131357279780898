import React, { useEffect, useRef, useState } from "react";
import toast, { Toaster } from 'react-hot-toast';
import copyIcon from "../resources/images/copy.png";
import store from "../store/store";


const RoomLabel = ({ roomId }) => {
  const roomRef = useRef(null);

  const copyRoomId = () => {
    const roomInput = roomRef.current;
    if (roomInput) {
      roomInput.select();
      document.execCommand("copy");
      // Deselect the input after copying
      roomInput.setSelectionRange(0, 0);
      toast.success('Meeting ID has been copied to your clipboard.');
    }
  };

  return (

    <>
      {roomId &&
        <div className="room_label">
          <p className="room_label_paragraph">
            Meeting ID: {roomId}{" "}
            <button className="copy_button" onClick={copyRoomId}>
              <img
                src={copyIcon}
                alt="Copy Icon"
                style={{ height: '16px', width: '16px' }}
              />
            </button>
          </p>
          <input
            ref={roomRef}
            type="text"
            value={roomId}
            readOnly
            style={{ position: 'absolute', left: '-9999px' }}
          />
        </div>

      }
      <Toaster />
    </>
  );
};

export default RoomLabel;
