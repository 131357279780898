import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { setIsRoomHost } from "../store/actions";
import JoinRoomTitle from "./JoinRoomTitle";
import JoinRoomContent from "./JoinRoomContent";
import controller from "../resources/images/contorller-c.png";
import blackcircuit from "../resources/images/b-c-r.png";

import "./JoinRoomPage.css";

const JoinRoomPage = (props) => {
  const { setIsRoomHostAction, isRoomHost } = props;

  const search = useLocation().search;

  useEffect(() => {
    const isRoomHost = new URLSearchParams(search).get("host");
    if (isRoomHost) {
      setIsRoomHostAction(true);
    }

  }, []);

  return (
    // <div className="join_room_page_container">
    //   <div className="join_room_page_panel" style={{marginTop: '-20vh'}}>
    //     <JoinRoomTitle isRoomHost={isRoomHost} />
    //     <JoinRoomContent />
    //   </div>
    // </div>
    <div className="join_room_page_container">
      <div className="join_room_page_panel" style={{marginTop: '-20vh'}}>
        <div className="login-header">{isRoomHost ? "Host meeting" : "Join meeting"}</div>
        <JoinRoomContent />
        {/* <RegisterButtons onLogin={onLogin} /> */}
        {/* <p className="bottom-text" style={{color: 'white'}}>Already have an account? <Link to="/login" style={{color: '#f3e885', textDecoration: 'none'}}>Login</Link> here.</p> */}
      </div>
      <img className="left-bg-img" src={blackcircuit}></img>
      <img className="right-bg-img" src={controller}></img>
    </div>
  );
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    setIsRoomHostAction: (isRoomHost) => dispatch(setIsRoomHost(isRoomHost)),
  };
};

export default connect(mapStoreStateToProps, mapActionsToProps)(JoinRoomPage);
