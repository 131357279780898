import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import IntroductionPage from "./IntroductionPage/IntroductionPage";
import JoinRoomPage from "./JoinRoomPage/JoinRoomPage";
import RoomPage from "./RoomPage/RoomPage";
import LoginPage from "./LoginPage/LoginPage";
import RegisterPage from "./RegisterPage/RegisterPage"
import ProfilePage from "./ProfilePage/ProfilePage";
import TermsPage from "./TermsPage/TermsPage";
import { connectWithSocketIOServer } from "./utils/wss";
import Header from "./Header";
import "./App.css";
import PrivacyPage from "./PrivacyPage/PrivacyPage";
import IndexPage from "./Index/IndexPage";

// Login middleware component
const PrivateRoute = ({ component: Component, loggedIn, ...rest }) => (
  <Route {...rest} render={(props) => (
    loggedIn
      ? <Component {...props} />
      : <Redirect to='/home' />
  )} />
);

function App() {
  const [loggedIn, setLoggedIn] = useState(true);

  useEffect(() => {
    connectWithSocketIOServer();
    const userLoggedIn = checkLoggedInStatus();
    setLoggedIn(userLoggedIn);
  }, []);

  const checkLoggedInStatus = () => {
    return localStorage.getItem("accessToken") !== null;
  };

  const handleLogin = () => {
    setLoggedIn(true);
  };

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    setLoggedIn(false);
    const siteUrl = window.location.origin;
    window.location.href = siteUrl;
  };

  return (
    <Router>
      <Header onLogout={handleLogout} />
      <Switch>
        <Route path="/login"><LoginPage onLogin={handleLogin} /></Route>
        <Route path="/register"><RegisterPage onLogin={handleLogin} /></Route>
        <Route path="/terms"><TermsPage /></Route>
        <Route path="/privacy"><PrivacyPage /></Route>
        <Route path="/home"><IndexPage onLogin={handleLogin} /></Route>
        <PrivateRoute path="/profile" component={ProfilePage} loggedIn={loggedIn} />
        <PrivateRoute path="/join-room" component={JoinRoomPage} loggedIn={loggedIn} />
        <PrivateRoute path="/room" component={RoomPage} loggedIn={loggedIn} />
        <PrivateRoute path="/" component={IntroductionPage} loggedIn={loggedIn} />
      </Switch>
    </Router>
  );
}

export default App;
