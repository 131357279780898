import React, { useEffect, useRef, useState } from "react";
import CameraButton from "./CameraButton";
import LeaveRoomButton from "./LeaveRoomButton";
import MicButton from "./MicButton";
import SwitchToScreenSharingButton from "./SwitchToScreenSharingButton";
import { connect } from "react-redux";
import store from "../../store/store";

const VideoButtons = (props) => {
  const { connectOnlyWithAudio } = props;
  const [elapsedTime, setElapsedTime] = useState(0);
  const roomCreatedAt = store.getState().roomCreatedAt;

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date();
      const createdAt = new Date(roomCreatedAt);
      const elapsedMilliseconds = currentTime - createdAt;
      const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
      setElapsedTime(elapsedSeconds);
    }, 1000);

    return () => clearInterval(interval);
  }, [roomCreatedAt]);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };


  return (
    <div className="video_buttons_container">
      <div className="meeting-timer">{formatTime(elapsedTime)}</div>
      <MicButton />
      {!connectOnlyWithAudio && <CameraButton />}
      <LeaveRoomButton />
      {!connectOnlyWithAudio && <SwitchToScreenSharingButton />}
    </div>
  );
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStoreStateToProps)(VideoButtons);
