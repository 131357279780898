import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../resources/images/logo.png";

import "./PrivacyPage.css";


const PrivacyPage = ({ onLogin }) => {

    return (
        <div className="no_meeting_card" style={{background: 'linear-gradient(237deg, #46b3ff 0%, #0752fe 100%)', fontFamily: '"Langar", system-ui'}}>
            <div className="no_meeting_card-2">
                <div>
                    <h1>Privacy Policy</h1>
                    <p>This Privacy Policy outlines how game-circuit.com collects, uses, and protects your personal information when
                        you use our video meeting platform.</p>
                    <b>1. Information We Collect</b>
                    <p>We collect the following types of information:
                    </p><ul>
                        <li>Personal Information: Such as your name, email address, and other details provided during account
                            registration.</li>
                        <li>Usage Data: Information about how you interact with game-circuit.com, including IP addresses, device
                            information, and usage patterns.</li>
                        <li>Video and Audio Data: Content shared during video meetings, debates, and discussions.</li>
                    </ul>
                    <p />
                    <b>2. How We Use Your Information</b>
                    <p>We use the collected information for purposes including, but not limited to:
                    </p><ul>
                        <li>Providing and improving our video meeting platform.</li>
                        <li>Communicating with users and responding to inquiries.</li>
                        <li>Ensuring the security and integrity of our platform.</li>
                    </ul>
                    <p />
                    <b>3. Sharing of Information</b>
                    <p>We do not sell, trade, or otherwise transfer your personal information to third parties. However, we may share
                        information with service providers who assist us in operating the platform and conducting our business.</p>
                    <b>4. Security</b>
                    <p>We take reasonable measures to protect your personal information from unauthorized access, disclosure,
                        alteration, and destruction. However, no method of transmission over the internet or electronic storage is
                        completely secure, and we cannot guarantee absolute security.</p>
                    <b>5. Your Choices</b>
                    <p>You have the right to access, correct, or delete your personal information. You may also choose to opt-out of
                        certain communications. Contact us at privacy@debateology.com for assistance.</p>
                    <b>6. Changes to this Privacy Policy</b>
                    <p>We may update this Privacy Policy from time to time. It is your responsibility to review the policy
                        periodically for any changes.</p>
                    <p>For any questions or concerns regarding your privacy, please contact us at privacy@debateology.com.</p>
                </div>

            </div>
        </div>
    );
};

export default PrivacyPage;
