import React from "react";
import { Button, Input, Space } from 'antd';
import { NumberOutlined, FileTextOutlined, ReadOutlined } from '@ant-design/icons';

const JoinRoomInputs = (props) => {
  const {
    roomIdValue,
    setRoomIdValue,
    nameValue,
    setNameValue,
    titleValue,
    setTitleValue,
    descriptionValue,
    setDescriptionValue,
    isRoomHost 
  } = props;

  return (
    <div className="connecting_buttons_container">
      {!isRoomHost ?

        <Input
        className="card-input"
          value={roomIdValue}
          size="large"
          style={{ marginTop: '15px' }}
          onChange={(e) => setRoomIdValue(e.target.value)}
          placeholder="Enter meeting ID"
          prefix={<NumberOutlined />}
        />

        :

        <>

          <Input
            value={titleValue}
            size="large"
            className="card-input"
            style={{ marginTop: '15px' }}
            onChange={(e) => setTitleValue(e.target.value)}
            placeholder="Enter meeting title"
            prefix={<FileTextOutlined />}
          />

          <Input
          className="card-input"
            value={descriptionValue}
            size="large"
            style={{ marginTop: '15px' }}
            onChange={(e) => setDescriptionValue(e.target.value)}
            placeholder="Enter meeting description"
            prefix={<ReadOutlined />}
          />

        </>

      }
      <div style={{ display: 'none' }}>

        <Input
          value={nameValue}
          size="small"
          onChange={(e) => setNameValue(e.target.value)}
          placeholder="Name"
        />
      </div>

    </div>
  );
};

export default JoinRoomInputs;
