import React, { useState } from "react";
import controller from "../resources/images/contorller-c.png";
import blackcircuit from "../resources/images/b-c-r.png";
import toast, { Toaster } from 'react-hot-toast';
import { EyeInvisibleOutlined, EyeTwoTone, MailOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { useHistory } from "react-router-dom";


import "./ProfilePage.css";
import store from "../store/store";
import { setAuth } from "../store/actions";

const ProfilePage = ({ onLogin }) => {
  const history = useHistory();
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;

  const [imageUrl, setImageUrl] = useState(user?.avatar || "");
  const [name, setName] = useState(user?.name || "");
  const [email, setEmail] = useState(user?.email || "");
  const [password, setPassword] = useState("");

  const handleProfileUpdate = async () => {
    try {
      const userId = user?.id;
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify({ userId, name, email, password }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || "Profile update failed");
      }

      localStorage.setItem("user", JSON.stringify(data.user));
      store.dispatch(setAuth(data.user));
      setName(data.user.name);
      setEmail(data.user.email);
      toast.success("Profile updated successfully");
      history.push("/");
    } catch (error) {
      console.error("Profile update error:", error);
      toast.error(error.message || "Profile update failed. Please try again.");
    }
  };

  const handleImageUpload = async (event) => {
    const formData = new FormData();
    formData.append('image', event.target.files[0]);
    formData.append('userId', user?.id);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/update-profile-picture`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: formData,
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || "Profile picture update failed");
      }
      localStorage.setItem("user", JSON.stringify(data?.user));
      setImageUrl(data?.user?.avatar);
      toast.success("Profile picture updated successfully");
    } catch (error) {
      console.error("Profile picture update error:", error);
      toast.error(error.message || "Profile picture update failed. Please try again.");
    }

  };



  return (
    <>
      <div className="profile_page_container">
        <div className="profile_page_panel"  style={{marginTop: '-20vh'}}>
          <div className="profile-pic">
            <label className="-label" htmlFor="file">
              <span className="glyphicon glyphicon-camera" />
              <span>Change Image</span>
            </label>
            <input id="file" type="file" accept="image/*" onChange={handleImageUpload} />
            <img src={imageUrl} id="output" width={200} />
          </div>

          <Input type="email"
            value={name}
            className="card-input"
            size="large"
            style={{ marginTop: '15px' }}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name" prefix={<UserOutlined />}
          />

          <Input type="email"
            value={email}
            className="card-input"
            size="large"
            style={{ marginTop: '15px' }}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email" prefix={<MailOutlined />}
          />

          <Input.Password
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="card-input"
            size="large"
            style={{ marginTop: '15px' }}
            placeholder="Password"
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />

          <Button
            size="large"
            className="card-button"
            block
            onClick={handleProfileUpdate}
            style={{ marginTop: '15px' }}
          >
            Update Profile
          </Button>

        </div>
        <img className="left-bg-img" src={blackcircuit}></img>
      <img className="right-bg-img" src={controller}></img>
      </div>
      <Toaster />
    </>
  );

};


export default ProfilePage;
