import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../resources/images/logo.png";

import "./TermsPage.css";


const TermsPage = ({ onLogin }) => {

    return (
        <div className="no_meeting_card" style={{background: 'linear-gradient(237deg, #46b3ff 0%, #0752fe 100%)', fontFamily: '"Langar", system-ui'}}>
            <div className="no_meeting_card-2">
                <div>
                    <h1>Terms of Service</h1>
                    <p>Welcome to Game-Circuit.com, a video meeting platform for engaging in thoughtful debates and discussions.</p>
                    <b>1. Acceptance of Terms</b>
                    <p>By using Game-Circuit.com, you agree to comply with and be bound by these Terms of Service. If you do not agree with these terms, please refrain from using the platform.</p>
                    <b>2. User Conduct</b>
                    <p>Users must adhere to ethical and respectful conduct while using Game-Circuit.com. Any form of harassment, hate speech, or inappropriate behavior will not be tolerated.</p>
                    <b>3. Privacy</b>
                    <p>We prioritize user privacy. Please refer to our <Link to="/privacy" style={{color: '#f3e885', textDecoration: 'none'}}>Privacy Policy</Link> for details on how we collect, use, and protect your personal information.</p>
                    <b>4. Intellectual Property</b>
                    <p>All content and materials on Game-Circuit.com are protected by intellectual property laws. Users must respect the rights of others and refrain from unauthorized use or reproduction of any content.</p>
                    <b>5. Disclaimer</b>
                    <p>Game-Circuit.com is provided "as is" without any warranties. We are not responsible for the accuracy or reliability of user-generated content.</p>
                    <b>6. Termination of Service</b>
                    <p>We reserve the right to terminate or suspend access to Game-Circuit.com at our discretion, without prior notice, for any reason, including breach of these Terms of Service.</p>
                    <b>7. Changes to Terms</b>
                    <p>We may update these Terms of Service from time to time. It is your responsibility to review the terms periodically for any changes.</p>
                    <p>For any questions or concerns, please contact us at support@game-circuit.com.</p>
                </div>

            </div>
        </div>
    );
};

export default TermsPage;
