import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import { EyeInvisibleOutlined, EyeTwoTone, MailOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import store from "../store/store";
import { setAuth } from "../store/actions";

const RegisterButtons = ({ onLogin }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [checked, setChecked] = useState(true);
  const history = useHistory();

  const handleRegister = async () => {
    try {
      const response = await fetch(`${process?.env?.REACT_APP_API_URL}/api/auth/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email, password }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || "Registration failed");
      }

      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("user", JSON.stringify(data.user));
      store.dispatch(setAuth(data.user));
      onLogin();
      history.push("/profile");
    } catch (error) {
      console.error("Registration error:", error);
      toast.error(error.message || "Registration failed. Please try again.");
    }
  };


  return (
    <>
      <div className="connecting_buttons_container">
        <Input
          className="card-input"
          value={name}
          size="large"
          onChange={(e) => setName(e.target.value)}
          placeholder="Name" prefix={<UserOutlined />}
          autoComplete="off"
        />

        <Input
          className="card-input"
          type="email"
          value={email}
          size="large"
          onChange={(e) => setEmail(e.target.value)}
          style={{ marginTop: '15px' }}
          placeholder="Email" prefix={<MailOutlined />}
          autoComplete="off"
        />

        <Input.Password
          className="card-input"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          size="large"
          placeholder="Password"
          style={{ marginTop: '15px' }}
          iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          autoComplete="off"
        />

        <p className="bottom-text" style={{ color: 'white' }}>By continuing, you agree to our <Link to="/terms" style={{ color: '#f3e885', textDecoration: 'none' }}>Terms of Use.</Link></p>

        <Button
          size="large"
          className="card-button"
          block
          onClick={handleRegister}
        >
          Register
        </Button>

      </div>
      <Toaster />
    </>

  );
};

export default RegisterButtons;
