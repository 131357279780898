import React, { useEffect, useState } from "react";
import arrow from "../resources/images/arrow.png";
import box from "../resources/images/box.png";
import icon from "../resources/images/video-conference.png";
import ConnectingButtons from "./ConnectingButtons";
import { connect } from "react-redux";
import { setIsRoomHost } from "../store/actions";
import { Avatar, Card, Dropdown, FloatButton, Tooltip, Badge } from 'antd';
import toast, { Toaster } from 'react-hot-toast';
import { VideoCameraOutlined, EllipsisOutlined, VideoCameraAddOutlined, UserOutlined } from '@ant-design/icons';
import "./IntroductionPage.css";
import { Link, useHistory } from "react-router-dom";

const IntroductionPage = ({ setIsRoomHostAction }) => {
  const [meetings, setMeetings] = useState([]);
  const { Meta } = Card;
  let history = useHistory();

  const items = [
    {
      label: 'Report',
      key: '1',
    }
  ];

  const handleMenuClick = (e) => {
    if (e.key === '1') {
      toast.success("Reported successfully");
    }
  }

  const pushToJoinRoomPageAsHost = () => {
    history.push("/join-room?host=true");
  };

  useEffect(() => {
    setIsRoomHostAction(false);

    fetch(`${process.env.REACT_APP_API_URL}/api/meetings`)
      .then((response) => response.json())
      .then((data) => setMeetings(data))
      .catch((error) => console.error("Error fetching meetings:", error));
  }, []);

  return (
    <div>
      <div className="meeting_cards_container">
        {!meetings.length &&
          <div className="no_meeting_card" >
            <div style={{ height: '8rem', width: '8rem', background: 'white', borderRadius: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img style={{ height: '6rem', width: '6rem', padding: '2rem', }} src={box} alt="box" />
            </div>
            <h1 className="default-header">There is no ongoing meeting</h1>
            {/* <div> */}
            <p className="default-paragraph">Create a new meeting instead by clicking the join meeting button below.</p>
            <div style={{ position: 'absolute', bottom: '9rem', right: '5rem' }}>
              <img style={{ height: '12rem' }} src={arrow} alt="Arrow pointing at the floating button" />
            </div>
            {/* </div> */}
          </div>
        }
        {meetings.map((meeting) => (
          <Badge.Ribbon key={meeting.id} color="volcano" className="meeting_badge" text="Ongoing">
            <Card
              className="meeting_card"
              title={meeting.title}
              actions={[
                <Link to={`/join-room?meetingId=${meeting.meetingID}`}><VideoCameraOutlined key="join" /> Join</Link>,

                <Tooltip title="Total participants" placement="top">
                  <Badge size="small" count={meeting.connectedUserCount}>
                    <Avatar style={{ backgroundColor: '#87d068' }} size={20} icon={<UserOutlined />} />
                  </Badge>
                </Tooltip>,

                <Dropdown menu={{
                  items,
                  onClick: handleMenuClick,
                }} placement="bottom" arrow>
                  <EllipsisOutlined />
                </Dropdown>
              ]}
            >
              <Card.Meta
                avatar={<Avatar size={64} shape="square" src={icon} />}
                description={meeting.description}
              />
              <p><strong>Host:</strong> <Avatar size={20} src={meeting.host.avatar} /> {meeting.host.name}</p>
              {/* <p>Total participants: {meeting.connectedUserCount}</p> */}
              <p><strong>Started at:</strong> {new Date(meeting.createdAt).toLocaleString()}</p>
            </Card>
          </Badge.Ribbon>
        ))}
        <FloatButton
          icon={< VideoCameraAddOutlined />}
          type="primary"
          href="/join-room?host=true"
          badge={{ dot: true }}
          style={{ height: '64px', width: "64px" }}
          tooltip={<div>Host a new meeting</div>}
        />
      </div>
      <Toaster />
    </div>
  );
};

const mapActionsToProps = (dispatch) => {
  return {
    setIsRoomHostAction: (isRoomHost) => dispatch(setIsRoomHost(isRoomHost)),
  };
};

export default connect(null, mapActionsToProps)(IntroductionPage);
