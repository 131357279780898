import React from "react";
import controller from "../resources/images/contorller-c.png";
import blackcircuit from "../resources/images/b-c-r.png";
import LoginButtons from "./LoginButtons";
import { Link } from "react-router-dom";
import "./LoginPage.css";

const LoginPage = ({ onLogin }) => {

  return (
    <div className="login_page_container">
      <div className="login_page_panel" style={{ marginTop: '-20vh' }}>
        <div className="login-header">Login</div>
        <LoginButtons onLogin={onLogin} />
        <p className="bottom-text" style={{ color: 'white' }}>New to Game-circuit? <Link to="/register" style={{ color: '#f3e885', textDecoration: 'none' }}>Sign up</Link> here.</p>
      </div>
      <img className="left-bg-img" src={blackcircuit} alt="left"></img>
      <img className="right-bg-img" src={controller} alt="right"></img>
    </div>
  );
};

export default LoginPage;
