import React from "react";
import ConnectingButton from "./ConnectingButton";
import { useHistory } from "react-router-dom";
import { Button, Input, Space } from 'antd';



const ConnectingButtons = () => {
  let history = useHistory();

  const pushToJoinRoomPage = () => {
    history.push("/join-room");
  };

  const pushToJoinRoomPageAsHost = () => {
    history.push("/join-room?host=true");
  };

  return (
    <div className="connecting_buttons_container">

      <Button
        type="primary"
        block
        onClick={pushToJoinRoomPage}
      >
        Join a meeting
      </Button>

      <Button
        block
        onClick={pushToJoinRoomPageAsHost}
        style={{ marginTop: '15px' }}
      >
        Host a meeting
      </Button>



    </div>
  );
};

export default ConnectingButtons;
