import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "./resources/images/logo.png";
import { Menu, Avatar } from "antd";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
// import { selectAuthUser } from "./store/reducer";

const Header = ({ onLogout }) => {

  // Fetch the user's name from localStorage or your user state
  // const user = localStorage.getItem("user")
  //   ? JSON.parse(localStorage.getItem("user"))
  //   : null;

  const user = useSelector(state => state.auth);

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    onLogout();
  };


  const backtoHome = () => {
    const siteUrl = window.location.origin;
    window.location.href = siteUrl;
  }

  const goToProfile = () => {
    const siteUrl = window.location.origin;
    window.location.href = siteUrl + '/profile';
  }

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <div style={{ cursor: 'pointer' }} onClick={backtoHome} className="navbar-item">
          <img style={{ width: '220px', marginTop: '5px' }} src={logo} className="introduction_page_image" alt="Logo" />
        </div>
      </div>
      {user && user.name ?
        <Menu mode="horizontal">
          <Menu.SubMenu
            title={
              <>
                <Avatar src={<img src={user?.avatar} alt="avatar" />} size="medium">
                </Avatar>
                <span className="username">{user?.name}</span>
              </>
            }
          >
            <Menu.Item key="profile" onClick={goToProfile}>
              <UserOutlined /> Profile
            </Menu.Item>
            <Menu.Item key="log-out" onClick={handleLogout}>
              <LogoutOutlined /> Logout
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>
        :
        <>
          <div className="header-links">
            <Link to='/login'>Login</Link>
            <Link to='/register'>Signup</Link>
          </div>
        </>
      }

    </nav>
  );
};

export default Header;
