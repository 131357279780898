const Actions = {
  SET_IS_ROOM_HOST: "SET_IS_ROOM_HOST",
  SET_CONNECT_ONLY_WITH_AUDIO: "SET_CONNECT_ONLY_WITH_AUDIO",
  SET_IDENTITY: "SET_IDENTITY",
  SET_TITLE: "SET_TITLE",
  SET_DESCRIPTION: "SET_DESCRIPTION",
  SET_ROOM_ID: "SET_ROOM_ID",
  SET_SHOW_OVERLAY: "SET_SHOW_OVERLAY",
  SET_PARTICIPANTS: "SET_PARTICIPANTS",
  SET_ROOM_CREATED_AT: "SET_ROOM_CREATED_AT",
  SET_MESSAGES: "SET_MESSAGES",
  SET_AUTH: "SET_AUTH",
  SET_ACTIVE_CONVERSATION: 'SET_ACTIVE_CONVERSATION',
  SET_DIRECT_CHAT_HISTORY: 'SET_DIRECT_CHAT_HISTORY',
  SET_SOCKET_ID: 'SET_SOCKET_ID'
};

export const setIsRoomHost = (isRoomHost) => {
  return {
    type: Actions.SET_IS_ROOM_HOST,
    isRoomHost,
  };
};

export const setConnectOnlyWithAudio = (onlyWithAudio) => {
  return {
    type: Actions.SET_CONNECT_ONLY_WITH_AUDIO,
    onlyWithAudio,
  };
};

export const setIdentity = (identity) => {
  return {
    type: Actions.SET_IDENTITY,
    identity,
  };
};

export const setTitle = (title) => {
  return {
    type: Actions.SET_TITLE,
    title,
  };
};

export const setDescription = (description) => {
  return {
    type: Actions.SET_DESCRIPTION,
    description,
  };
};

export const setRoomId = (roomId) => {
  return {
    type: Actions.SET_ROOM_ID,
    roomId,
  };
};

export const setShowOverlay = (showOverlay) => {
  return {
    type: Actions.SET_SHOW_OVERLAY,
    showOverlay,
  };
};

export const setParticipants = (participants) => {
  return {
    type: Actions.SET_PARTICIPANTS,
    participants,
  };
};

export const setRoomCreatedAt = (roomCreatedAt) => {
  return {
    type: Actions.SET_ROOM_CREATED_AT,
    roomCreatedAt,
  };
};

export const setMessages = (messages) => {
  return {
    type: Actions.SET_MESSAGES,
    messages,
  };
};

export const setAuth = (auth) => {
  return {
    type: Actions.SET_AUTH,
    auth,
  };
};

export const setActiveConversation = (activeConversation) => {
  return {
    type: Actions.SET_ACTIVE_CONVERSATION,
    activeConversation
  }
}

export const setDirectChatHistory = (directChatHistory) => {
  return {
    type: Actions.SET_DIRECT_CHAT_HISTORY,
    directChatHistory
  }
}

export const setSocketId = (socketId) => {
  return {
    type: Actions.SET_SOCKET_ID,
    socketId
  }
}

export default Actions;
